<template>
  <div style="width: 90%;margin: 10% auto;">
    <img src="img/logo-minhaplataforma.png">
    <el-form label-position="top">
      <el-form-item label="Nome de Usuário">
        <el-input v-model="formData.username" placeholder="seu@email.com"></el-input>
      </el-form-item>
      <el-form-item label="Senha">
        <el-input v-model="formData.password" placeholder="sua senha" type="password"></el-input>
      </el-form-item>
      <div style="display: flex;justify-content: flex-end">
        <el-button type="primary" @click="doLogin()">Entrar</el-button>
      </div>
    </el-form>
  </div>
</template>


<script setup>

import {ref} from 'vue';
import {useRouter} from 'vue-router'
import {useStore} from 'vuex';


const router = useRouter();
const store = useStore();


const formData = ref({username: '',password: ''});


const doLogin = ()=>{

  store.commit("setAuth",true);

  router.push('/home');
}


</script>